<div class="mobile-devices">
    <div class="mobile-devices__items">

        @if ((isSkeleton$ | async)) {
            <ngx-skeleton-loader
                [theme]="{ width: '100%', height: '204px', margin: '1px 0 -1px 0', padding: 0, backgroundColor: 'var(--backgroundSkeleton)', borderRadius: '8px' }"
                count="5"></ngx-skeleton-loader>
        } @else {

            @if ((rows$ | async)?.length) {

                @for (item of rows$ | async; track item.registrator.id) {

                    @if (item?.sensors?.length) {
                        <div class="mobile-devices__item">
                            <div class="mobile-devices__header"
                                 [class.mobile-devices__header--offline]="!item.registrator.isConnect || !item.registrator.isActive">
                                <div class="mobile-devices__header-icon">

                                    <svg-icon
                                        [src]="item.registrator.creationType === creationType.ORIGIN
                                ? MOBILE_REGISTRATOR_ICON_PATH : VIRTUAL_REGISTRATOR_ICON_PATH"></svg-icon>


                                    <div class="mobile-devices__header-name">{{ item.registrator.name }}</div>

                                    <div class="mobile-devices__header-connect"
                                         [class.mobile-devices__header-connect--offline]="!item.registrator.isConnect"
                                         [class.mobile-devices__header-connect--unActive]="!item.registrator.isActive"
                                    >
                                    </div>

                                </div>
                                <div
                                    *ngIf="item.registrator.creationType === creationType.ORIGIN
                            && item.registrator.registratorType === registratorTypeEnum.android"
                                    class="mobile-devices__sensor-icons"
                                    [class.mobile-devices__sensor-icons--offline]="!item.registrator.isConnect || !item.registrator.isActive">
                                    <div class="mobile-devices__sensor-icon mobile-devices__sensor-icon--signal"
                                         [class.mobile-devices__sensor-icon--attention]="item.registrator.signal < -60 && item.registrator.signal >= -75"
                                         [class.mobile-devices__sensor-icon--alarm]="item.registrator.signal < -75"
                                         [class.mobile-devices__sensor-icon--offline]="!item.registrator.isConnect ||
                            !item.registrator.isActive"
                                    >
                                        <svg-icon [src]="SIGNAL_ICON_PATH"></svg-icon>

                                        @if (!item.registrator.isConnect || !item.registrator.isActive) {
                                            NA
                                        } @else {
                                            <span>{{ item.registrator.signal }} {{ 'devices.signalStatus' | translate }}</span>
                                        }
                                    </div>

                                    <div class="mobile-devices__sensor-icon mobile-devices__sensor-icon--battery"
                                         [class.mobile-devices__sensor-icon--attention]="item.registrator.battery <= 30 && item.registrator.battery > 15"
                                         [class.mobile-devices__sensor-icon--alarm]="item.registrator.battery <= 15">
                                        <svg-icon [src]="BATTERY_ICON_PATH"></svg-icon>


                                        <ng-container
                                            *ngIf="!item.registrator.isConnect || !item.registrator.isActive; else battery">
                                            NA
                                        </ng-container>

                                        <ng-template #battery>
                                    <span [ngSwitch]="item.registrator.battery">
                                        <ng-template
                                            [ngSwitchCase]="'-'">{{ item.registrator.battery }}</ng-template>
                                        <ng-template
                                            [ngSwitchCase]="'Na'">{{ item.registrator.battery }}</ng-template>
                                            <ng-template
                                                [ngSwitchCase]="'PS'">{{ item.registrator.battery }}</ng-template>
                                        <ng-template
                                            ngSwitchDefault>{{ item.registrator.battery + '%' }}</ng-template>
                                    </span>
                                        </ng-template>
                                    </div>

                                </div>
                            </div>


                            <div class="mobile-devices__body">

                                @for (sensor of item.sensors; track sensor.id) {
                                    <div class="mobile-devices__sensors">
                                        <div class="mobile-devices__sensor-header">
                                            <div class="mobile-devices__sensor-name"
                                                 [class.mobile-devices__sensor-name--offline]="!sensor.sleepStatus && (!sensor.isConnect || !sensor.isActive)"
                                                 [class.mobile-devices__sensor-name--sleep]="sensor.sleepStatus && item.registrator.isConnect">
                                                {{ sensor.name }}
                                            </div>
                                            <div *ngIf="item.registrator.creationType === creationType.ORIGIN"
                                                 class="mobile-devices__sensor-icons"
                                                 [class.mobile-devices__sensor-icons--offline]="!sensor.isConnect || !sensor.isActive">
                                                <div
                                                    class="mobile-devices__sensor-icon mobile-devices__sensor-icon--signal"
                                                    [class.mobile-devices__sensor-icon--attention]="sensor.signal < -60 && sensor.signal >= -75"
                                                    [class.mobile-devices__sensor-icon--alarm]="sensor.signal < -75"
                                                    [class.mobile-devices__sensor-icon--offline]="!sensor.isConnect ??
                                        !sensor.isActive"
                                                >
                                                    <svg-icon [src]="SIGNAL_ICON_PATH"></svg-icon>


                                                    <ng-container *ngIf="(sensor.sleepStatus && !item.registrator.isConnect)
                                            || !sensor.sleepStatus && (!sensor.isConnect || !sensor.isActive); else signal">
                                                        NA
                                                    </ng-container>

                                                    <ng-template #signal>
                                                 <span [ngSwitch]="sensor.signal">
                                                    <ng-template [ngSwitchCase]="'-'">{{ sensor.signal }}</ng-template>
                                                    <ng-template [ngSwitchCase]="'Na'">{{ sensor.signal }}</ng-template>
                                                    <ng-template
                                                        ngSwitchDefault>{{ sensor.signal }} {{ 'devices.signalStatus' | translate }}</ng-template>
                                                 </span>
                                                        <!--                                                <span>{{sensor.signal}} {{'devices.signalStatus' | translate}}</span>-->
                                                    </ng-template>
                                                </div>

                                                <div
                                                    class="mobile-devices__sensor-icon mobile-devices__sensor-icon--battery"
                                                    [class.mobile-devices__sensor-icon--attention]="sensor.battery <= 30 && sensor.battery > 15"
                                                    [class.mobile-devices__sensor-icon--alarm]="sensor.battery <= 15">
                                                    <svg-icon [src]="BATTERY_ICON_PATH"></svg-icon>

                                                    <ng-container *ngIf="(sensor.sleepStatus && !item.registrator.isConnect)
                                            || !sensor.sleepStatus && (!sensor.isConnect || !sensor.isActive); else battery">
                                                        NA
                                                    </ng-container>

                                                    <ng-template #battery>
                                                <span [ngSwitch]="sensor.battery">
                                                <ng-template [ngSwitchCase]="'-'">{{ sensor.battery }}</ng-template>
                                                <ng-template [ngSwitchCase]="'Na'">{{ sensor.battery }}</ng-template>
                                                <ng-template ngSwitchDefault>{{ sensor.battery + '%' }}</ng-template>
                                                    </span>
                                                    </ng-template>
                                                </div>

                                            </div>
                                        </div>

                                        <div class="mobile-devices__content">

                                            @for (variable of sensor.variableGroup; track variable.variableId) {
                                                <div class="mobile-devices__variables"
                                                     [class.mobile-devices__variables--offline]="!variable.isConnect || !sensor.isConnect || !sensor.isActive"
                                                     [class.mobile-devices__variables--attention]="variable.status === alarmTypeEnum.attention"
                                                     [class.mobile-devices__variables--alarm]="variable.status === alarmTypeEnum.alarm"
                                                >

                                                    @if ((sensor.sleepStatus && !item.registrator.isConnect)
                                                    || !sensor.sleepStatus && (!variable.isConnect || !sensor.isConnect || !sensor.isActive)) {
                                                        NA
                                                    } @else {

                                                        <span class="mobile-devices__variable-value"
                                                              [class.mobile-devices__variable-value--ok]="variable.status === alarmTypeEnum.ok"
                                                              [class.mobile-devices__variable-value--attention]="variable.status === alarmTypeEnum.attention"
                                                              [class.mobile-devices__variable-value--alarm]="variable.status === alarmTypeEnum.alarm"
                                                              [class.mobile-devices__variable-value--offline]="sensor.sleepStatus || !variable.isConnect
                                                      || !sensor.isActive || !sensor.isConnect"
                                                        >{{ variable.currentValue + variable.unit }}</span>
                                                    }
                                                    <span class="mobile-devices__sensor-name"
                                                          [class.mobile-devices__sensor-name--offline]="sensor.sleepStatus || !variable.isConnect
                                                  || !sensor.isConnect || !sensor.isActive"
                                                    >{{ variable.currentName }}</span>
                                                </div>
                                            }

                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                }

            } @else {
                <h1 class="mobile-devices__empty" [translate]="'devices.empty'"></h1>
            }

        }
    </div>
</div>
